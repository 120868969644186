.container {
    display: flex;
    flex-flow: row;
    height: 100%;
    align-items: stretch;
    gap: 24px;
    padding: 0px 24px 0px 24px;
}

.selectedDate {
    color: #0078ab;
}

.colLeft {
    flex-grow: 1;
    max-height: 500px;
    border-radius: 8px;
    max-width: 224px;
    min-width: 200px;
    box-shadow: 1px 1px 20px rgba(44, 52, 57, 0.15);
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        padding: 0px 24px 0px 24px;
        p {
            margin-left: 8px;
        }
    }
}
.colRight {
    flex-grow: 4;
    border-radius: 8px;
}

.tableContainer {
    th {
        z-index: 0 !important;
    }
    height: 100%;
}

.dot {
    background-color: gray;
    max-width: 16px;
    height: 16px;
    border-radius: 8px;
    flex: 1;
}
.data {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    text-align: start;
    .dateHeader {
        font-weight: 600;
    }
}
.data:hover {
    color: #0078ab;
}

.dateButtonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    flex: 4;
}

.barContainer {
    scroll-behavior: smooth;
    overflow: scroll;
    padding: 0px 24px 0px 24px;
}

.bar {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    &:before {
        content: "";
        display: inline-block;
        width: 2px;
        background: #d9d9d9;
        position: absolute;
        left: 5px;
        top: 5px;
        height: calc(100% - 10px);
    }

    li {
        cursor: pointer;
        position: relative;
        padding-left: 15px;
        margin-bottom: 15px;
        &:before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background: #d9d9d9;
            position: absolute;
            left: 0;
            top: 5px;
            border-radius: 10px;
        }
    }
}

.rowDeleted {
    background-color: #d62e4f18;
}
.rowInserted {
    background-color: #97be7a18;
}

.changesFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 24px;
    align-items: center;
    .filterOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }
    .datePicker {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        font-size: small;
        font-weight: bold;
        input {
            padding: 6px 16px 6px 16px;
            border-radius: 5px;
            border: 1px solid #ccd6db;
            z-index: 10;
        }
    }
    .button {
        height: 36px;
    }
    .downloadButton {
        color: #bbbdbf;
        cursor: pointer;
    }
}

.entitySelect {
    display: flex;
    align-items: center;
    span {
        padding: 0px 16px 0px 16px;
        font-weight: bold;
    }
    min-width: 240px;
}
