
.arrow {
    margin-left: 32px;
    margin-top: 90px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0E3348;
    p{
        color: white;
    }
}

.icon{
    color: white;
    background-color: white;
    margin: 0px 12px 0px 12px;
}

.titleBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    .title{
        font-size: 20px;
        margin-left: 8px;
    }
    img{
        margin-left: 16px;
    }
}

