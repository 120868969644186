.title{
    margin-right: 16px;
    font-size: 14px;
}

.containerUser{
    display: flex;
    flex-direction: row;
    align-items: center;
    svg{
        color: white;
        margin-right: 8px;
    }
}

.icon{
    background-color: white;
    margin-right: 16px;
}