.inlineContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inspectFilterMenu {
    padding: 24px 24px 0px 24px;

    .inspectFilters  {
        border-right: 1px solid black;
    }
}

.container {
    justify-content: space-between;
    margin: 0px 36px 0px 36px;
    padding: 51px 0px 26px 0px;
    border-bottom: 1px solid #ccd6db;
    flex: 0 1 auto;
}

.entitySelect {
    display: flex;
    align-items: center;
    span {
        padding: 0px 16px 0px 16px;
        font-weight: bold;
    }
    min-width: 240px;
    height: auto !important;
}

.actionButton {
    margin-left: 17px;
    height: 36px;
}

.inspectButton {
    margin-left: 54px;
}

.applyBox {
    flex-direction: row-reverse;
    flex-grow: 1;
}

.inspectBox {
    flex-grow: 2;
    border-right: 1px solid #ccd6db;
}
