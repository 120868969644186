.jsonBox{
    width: 100%;
    border: 1px solid #CCD6DB;
    background-color: #FAFAFB;
    border-radius: 4px 4px 0px 0px;
    .headerJsonBox{
        background-color: #CCD6DB;
        text-align: start;
        padding: 16px 0px 16px 0px;
        
        padding-left: 16px;
    }
    .bodyJsonBox{
        text-align: left;
        overflow: scroll;
        max-height: 464px;
        pre{
            margin: 24px 24px 0px 24px;
            font-size: 16px;
        }
    }
}