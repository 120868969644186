.container{
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.containerTabs{
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    height: 100%;
}
