@import "styles/base.scss";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;400;500;600;700;800&display=swap");

body {
    margin: 0;
    background: #e5ebed;
    font-family: Public Sans, sans-serif;
    text-align: left;
}

.relocation {
    margin-top: 32px;
    width: 1440px;
}

.loading-circle {
    align-self: center;
    margin-top: 80px;
}
.loading-line {
    flex-grow: 1;
}

.warning-alert {
    flex: 1;
    margin-right: 16;
    max-height: 24px;
}
.MuiInputBase-inputMarginDense {
    padding: 10 !important;
}
