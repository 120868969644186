.container{
    display: flex;
    flex-flow: column;
    margin:24px;
    border-radius: 8px;
    box-shadow: 1px 1px 20px rgba(44, 52, 57, 0.15);
    flex: 1 1 auto;
}

.modalHeader{
    border-bottom: 1px solid #CCD6DB;
    flex: 0 1 auto;
    p {
        text-align: start;
        padding-left: 33px;
        padding-top: 12px;
        font-size: 14px;
        font-weight: 600;
    }
}
.title{
    text-align: left;
    padding-left: 24px;
    border-bottom: #CCD6DB solid 1px;
    padding-bottom: 8px;
    margin-bottom: 0px;

}

.modalBody{
    flex: 1 1 auto;
    padding: 16px;
}