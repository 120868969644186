.footer{
    min-height: 42px;
    background-color: #F5F7F8;
    width: 100%;
    text-align: start;
    font-size: 10px;
    color:#969A9C;
    display: flex;
    align-items: center;
    p{
        margin-left: 39px;
    }
}