.container{   
    height: 100vh;
    display: flex;
    flex-flow: column;

}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  
  .header {
    flex: 0 1 auto;
  }
  
  .content {
    flex: 1 1 auto;
    margin: 0px 24px;

    .bodyContent{
      display: flex;
      flex-flow: column;
      height: 100%;
    }

  }
  
  .footer {
    flex: 0 1 40px;
  }